import React from 'react'

interface MeterNeedleProps {
  fillColor: string;
  className?: string
  style?: React.CSSProperties
}

const MeterNeedle: React.FC<MeterNeedleProps> = ({ fillColor, className, style }) => {
  return (
    <svg
      width="34"
      height="100"
      viewBox="0 0 34 100"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path d="M1 12.8681L12.5 1L24 12.8681L24 101.47V109H1V101.47V12.8681Z"
        fill={fillColor}
        stroke="#f9f9f9"
        stroke-width="2"
        stroke-linejoin="round" />

    </svg>
  )
}

export default MeterNeedle