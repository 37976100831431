import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from 'root/store/context/store-context';
import './allergies.less'

import { getAllergies } from 'root/helpers/api'

import image from 'images/kanta_logo.png';
import icon from 'images/icons/hand-icon.svg'
// TODO: move types to TS interfaces 
const Allergies = observer(() => {
    const { t } = useTranslation();
    const { patientStore } = useStore();

    const [allergies, setAllergies] = useState(
        {
            resourceType: "",
            type: "",
            entry: [{
                resource: {
                    resourceType: "",
                    id: "",
                    patient: {
                        reference: ""
                    },
                    code: {
                        coding: [
                            {
                                system: "",
                                code: "",
                                display: ""
                            }
                        ],
                        text: "",
                        translation: [
                            {
                                system: "",
                                code: "",
                                display: ""
                            }
                        ]
                    },
                    clinicalStatus: "",
                    reaction: [
                        {
                            manifestation: [
                                {
                                    coding: [
                                        {
                                            system: "",
                                            code: "",
                                            display: ""
                                        }
                                    ],
                                    text: "",
                                    translation: [
                                        {
                                            system: "",
                                            code: "",
                                            display: ""
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            }]
        })


    useEffect(() => {
        const fetchData = async () => {
            const id = patientStore.patientId?.toString()
            if (id) {
                const data = await getAllergies(id)
                return data
            }
            return null
        }
        const result = fetchData().then((data) => setAllergies(data))
    }, [])

    const hasAllergy = (allergy) => {
        if (allergy == 'Aspiriiniallergia')
            return '#FCAE1E'
        return ''
    }


    return (
        <div className="hippo-module allergies-container">
            <h2>{t('Drug allergies')}</h2>
            <img className="module-icon" src={icon} />
            <div className="allergies-list">
                {allergies.entry.map((allergy, i) =>
                    <div className="allergy-container hippo-list-item" style={{ color: hasAllergy(allergy.resource.code.translation[0].display) }} key={i}>
                        <div className="allergy-header" >{allergy.resource.code.translation[0].display}</div>
                        <div className="allergy-symptom">{allergy.resource.reaction.map((reaction) => reaction.manifestation.map((manifestation) => manifestation.translation[0].display))}</div>
                    </div>
                )}
            </div>
            <div className="hippo-source">
                <span className="source-txt"></span>
                <img className="source-logo" src={image} />
            </div>
        </div>
    )
})

export default Allergies