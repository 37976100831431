{
    "Dashboard": "Dashboard",
    "Patient search": "Patient search",
    "Sign out": "Sign out",
    "Summary": "Summary",
    "Basic information": "Basic information",
    "Age": "Age",
    "Gender": "Gender",
    "Height": "Height",
    "Weight": "Weight",
    "Body mass index": "Body mass index",
    "Smokes": "Smokes",
    "Chronic diseases": "Chronic diseases",
    "Drug allergies": "Drug allergies",
    "Blood pressure": "Blood pressure",
    "Medication list": "Medication list",
    "Resting heart rate": "Resting heart rate",
    "Daily average steps": "Daily average steps",
    "Patient self-measurements": "Patient self-measurements",
    "Search": "Search",
    "Search for a patient": "Search for a patient",
    "My patients": "My patients",
    "Non-hippo users": "Patients in national repository",
    "Date": "Date",
    "Systolic": "Systolic",
    "Diastolic": "Diastolic",
    "Average": "Average",
    "Steps": "Steps",
    "Lab results": "Lab results",
    "High": "High",
    "Low": "Low"
}