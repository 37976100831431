import React, { useEffect, useRef, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useStore } from 'root/store/context/store-context'
import _ from 'lodash'
import './lab-results.less'

import { getLabResults } from 'root/helpers/api'

import image from 'images/kanta_logo.png'
import moduleIcon from 'images/icons/user-info-icon.svg'
import MeterNeedle from 'images/components/meter-needle'
import closeIcon from 'images/icons/close.svg'


const LabResults = observer(() => {
    const { t, i18n } = useTranslation()
    const { patientStore } = useStore()
    const localeString = i18n.language == 'fi' ? 'fi-FI' : 'en-US'

    const [results, setResults] = useState({
        entry: [
            {
                resource:
                {
                    referenceRange: [{
                        high: {
                            unit: "",
                            code: "",
                            value: Number
                        },
                        low: {
                            unit: "",
                            code: "",
                            value: Number
                        }
                    }],
                    code: {
                        coding: [{
                            system: "",
                            code: "",
                            display: ""
                        }],
                        text: ""
                    },
                    valueQuantity: {
                        unit: "",
                        system: "",
                        code: "",
                        value: Number
                    },
                    effectiveDateTime: ""
                }
            }
        ]
    })

    const [selectedResultType, setSelectedResultType] = useState(null)
    const [selectedDate, setSelectedDate] = useState("")
    const hasAnimated = useRef(false)

    useEffect(() => {
        const fetchData = async () => {
            const id = patientStore.patientId?.toString()
            if (id) {
                const data = await getLabResults(id)
                return data
            }
            return null
        }
        const result = fetchData().then((data) => setResults(data))
    }, [])

    const resultsByDate = _(results.entry)
        .map(entry => entry.resource)
        .filter(resource => {
            const dateStr = resource.effectiveDateTime
            return !!dateStr && !isNaN(new Date(dateStr).getTime())
        })
        .groupBy(resource => {
            const dateStr = resource.effectiveDateTime
            return new Date(dateStr).toISOString().split('T')[0]
        })
        .toPairs()
        .sortBy(([date]) => new Date(date).getTime())
        .reverse()
        .map(([date, resourceArray]) => ({
            date,
            observations: resourceArray
        }))
        .value()

    useEffect(() => {
        if (selectedDate == "" && resultsByDate.length > 0) {
            setSelectedDate(resultsByDate[0].date)
        }
    }, [resultsByDate, selectedDate])

    const filteredByDate = resultsByDate.find(dateGroup => dateGroup.date === selectedDate)?.observations || []

    // TODO: modify type from any to object type when we have it done
    // TODO: handle more complex cases of referencerange
    const isBetweenReferenceValues = (resource : any) => {        
        if (resource.valueQuantity.value > resource.referenceRange[0].high.value ||
            resource.valueQuantity.value < resource.referenceRange[0].low.value
        )
            return "orange"
    }

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString)
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const formatReferenceRange = (referenceRange : any) => {
        const low = referenceRange.low.value
        const high = referenceRange.high.value

        return `(${low} - ${high})`
    }

    const ReferenceRangeBar = ({ resource }: { resource: any }) => {
        const referenceRange = resource.referenceRange[0]
        const low = referenceRange.low.value
        const high = referenceRange.high.value
        const value = resource.valueQuantity.value
      
        let min = Math.min(low - (high - low) * 0.5, value)
        let max = Math.max(high + (high - low) * 0.5, value)
      
        const range = max - min
        const padding = range * 0.05
      
        min = Math.max(min - padding, 0)
        max = max + padding
      
        const lowPercent = ((low - min) / (max - min)) * 100
        const highPercent = ((high - min) / (max - min)) * 100
        const valuePercent = ((value - min) / (max - min)) * 100
      
        return (
          <div className="reference-range-container">
            <div className="reference-range-bar outside-range left" style={{ width: `${lowPercent}%` }}></div>
            <div className="reference-range-bar" style={{ left: `${lowPercent}%`, width: `${highPercent - lowPercent}%` }}></div>
            <div className="reference-range-bar outside-range right" style={{ width: `${100 - highPercent}%`, left: `${highPercent}%` }}></div>
            <div className="range-tick" style={{ left: `calc(${lowPercent}% - 1px)` }}></div>
            <div className="range-tick" style={{ left: `calc(${highPercent}% - 1px)` }}></div>
            <div className="value-indicator" style={{ left: `calc(${valuePercent}% - 1px)` }}>
                <div className="inner-value-circle"></div>
            </div>
          </div>
        )
    }

    const ReferenceRangeMeter = ({ resource, hasAnimated }: { resource: any, hasAnimated: React.MutableRefObject<boolean> }) => {
        const needleStart = -120
        const needleEnd = 120

        const referenceRange = resource.referenceRange[0]
        const low = referenceRange.low.value
        const high = referenceRange.high.value
        const value = resource.valueQuantity.value

        const isWithinRange = value >= low && value <= high

        let min = Math.min(low - (high - low) * 0.5, value)
        let max = Math.max(high + (high - low) * 0.5, value)
        const range = max - min
        const padding = range * 0.05

        min = Math.max(min - padding, 0)
        max = max + padding

        const lowPercent = ((low - min) / (max - min)) * 100
        const highPercent = ((high - min) / (max - min)) * 100

        // If clip-path changes, these need to be changed as well
        const needlePosition = ((value - min) / (max - min)) * (needleEnd - needleStart) + needleStart
        const startAngle = 57 
        const endAngle = 303 

        const [rotation, setRotation] = useState(hasAnimated.current ? needlePosition : needleStart)

        const totalAngleRange = endAngle - startAngle

        const lowAngle = startAngle + (lowPercent / 100) * totalAngleRange
        const highAngle = startAngle + (highPercent / 100) * totalAngleRange

        // Add a 2 degree of gap between values for gradient smoothing
        const adjustedLowAngle = lowAngle + 2
        const adjustedHighAngle = highAngle - 2
        
        const gradient = low === 0
            ? `conic-gradient(var(--c-hippo-green) ${adjustedLowAngle}deg ${adjustedHighAngle}deg, #FFC067 ${highAngle}deg ${endAngle}deg)`
            : `conic-gradient(#FFC067 ${startAngle}deg ${lowAngle}deg, var(--c-hippo-green) ${adjustedLowAngle}deg ${adjustedHighAngle}deg, #FFC067 ${highAngle}deg ${endAngle}deg)`
        
        useEffect(() => {
            const timeout = setTimeout(() => {
                setRotation(needlePosition)
                hasAnimated.current = true
            }, 100)

            return () => clearTimeout(timeout)
        }, [needlePosition, hasAnimated]) 

        return (
            <div className="reference-range-meter-container">
                <div className="meter-circle" style={{ background: gradient }}></div>
                <div className="meter-inner-circle"></div>
                <MeterNeedle
                    fillColor={isWithinRange ? "var(--c-hippo-green)" : "var(--c-hippo-red)"}
                    className={"meter-needle"}
                    style={{
                        transform: `translate(-50%, -100%) rotate(${rotation}deg)`,
                        transition: 'transform 2s ease',
                    }}
                />
            </div>
        )
    }

    const handleEntryClick = (labResult: any) => {
        setSelectedResultType(labResult.code.text)
    }

    const handleChartCloseClick = () => {
        setSelectedResultType(null)
    }

    const RenderLineChart = ({ resultType, results, locale, t }) => {
        //TODO: this is duplicated across files
        const CustomToolTip = ({ active, payload, label}: any) => {
            const dateOptions: Intl.DateTimeFormatOptions = {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }

            if (active && payload && payload.length) {
              return (
                <div className="hippo-charts-tooltip">
                  <div className="tooltip-header">{`${new Date(label).toLocaleString(locale, dateOptions)}`}</div>
                  <div className="tooltip-content">
                    <div>{`${payload[0].name}: ${payload[0].value}`}</div>
                  </div>
                </div>
              )
            }
        }

        const filteredResults = results.entry.filter(result => result.resource.code.text === resultType)

        const data = filteredResults
            .map(result => ({
                date: new Date(result.resource.effectiveDateTime).getTime(),  
                [result.resource.code.text]: result.resource.valueQuantity.value
            }))
            .sort((a, b) => a.date - b.date)  
        
        //TODO: what if multiple reference ranges?
        const highValue = filteredResults[0].resource.referenceRange?.[0]?.high?.value
        const lowValue = filteredResults[0].resource.referenceRange?.[0]?.low?.value

        const stdDev = (highValue - lowValue) / 4

        const getPrecision = (value: number) => {
            const decimalPart = value.toString().split('.')[1]
            return decimalPart ? decimalPart.length : 0
        }

        const precision = Math.max(getPrecision(highValue), getPrecision(lowValue))

        const roundValue = (value: number) => parseFloat(value.toFixed(precision))

        const yMin = roundValue(Math.max(0, lowValue - stdDev))
        const yMax = roundValue(highValue + stdDev)

        return (
            <ResponsiveContainer width="88%" height={300}>
                <LineChart className="bp-chart" data={data} margin={{ top: 5, right: 20, bottom: 10, left: -20 }}>
                    <Line type="monotone" dataKey={resultType} stroke="#00B0B9" strokeWidth={3} />
                    <XAxis 
                        dataKey="date" 
                        fontSize={12} stroke='#ffffff'
                        tickFormatter={(label) => `${new Date(label).toLocaleString(locale, { year: 'numeric', month: 'numeric', day: 'numeric' })}`}
                        type="number"
                        domain={['dataMin', 'dataMax']}
                    />
                    <YAxis 
                        dataKey={resultType} 
                        fontSize={12} 
                        stroke='#ffffff' 
                        domain={[yMin, yMax]} 
                        padding={{ top: 10, bottom: 10 }}
                    />
                    <Tooltip content={<CustomToolTip />} />
                    <ReferenceLine 
                        y={highValue} 
                        stroke="orange"
                        label={{ value: `${t('High')}: ${highValue}`, position: 'top', fill: '#ebebebdc', fontSize: 12 }} 
                    />
                    <ReferenceLine 
                        y={lowValue} 
                        stroke="orange"
                        label={{ value: `${t('Low')}: ${lowValue}`, position: 'top', fill: '#ebebebdc', fontSize: 12 }} 
                    />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    return (
        <>
            {<div className={`hippo-module lab-results ${filteredByDate.length > 6 ? 'extended' : ''}`}>
                <h2 className="header2">{t('Lab results')}</h2>
                <img className="module-icon" src={moduleIcon} />

                <select className="date-selector" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
                    {resultsByDate.map(({ date }) => (
                        <option key={date} value={date}>
                            {formatDate(date)}
                        </option>
                    ))}
                </select>

                <div className="lr-container">
                    {filteredByDate.length > 0 && (
                        filteredByDate.map((labResult, i) => (
                            <div className="hippo-list-item laboratory-entry" key={i} onClick={() => handleEntryClick(labResult)}>
                                <div className="lab-row">
                                    <ReferenceRangeMeter resource={labResult} hasAnimated={hasAnimated} />
                                    <div className="lab-info">
                                        <div className="lab-identifier" style={{ color: isBetweenReferenceValues(labResult) }}>
                                            {labResult.code.text}
                                        </div>
                                        <div className="lab-results" style={{ color: isBetweenReferenceValues(labResult) }}>
                                            <div>{labResult.valueQuantity.value.toString()}</div>
                                            <div>{labResult.valueQuantity.unit}</div>
                                            <div className="lab-reference-range">{formatReferenceRange(labResult.referenceRange[0])}</div>
                                        </div>
                                    </div>
                                    <div className="lab-date">{formatDate(labResult.effectiveDateTime)}</div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div className="hippo-source">
                    <span className="source-txt"></span>
                    <img className="source-logo" src={image} />
                </div>
            </div>}

            {selectedResultType && (
                <div className="hippo-module lab-results-chart">
                    <h2 className="header2">{selectedResultType}</h2>
                    <img className="module-icon close-icon" src={closeIcon} onClick={() => handleChartCloseClick()}/>
                    <div className="lr-container">
                        <RenderLineChart resultType={selectedResultType} results={results} locale={localeString} t={t}/>
                    </div>
                    <div className="hippo-source">
                        <span className="source-txt"></span>
                        <img className="source-logo" src={image} />
                    </div>
                </div>
            )}
        </>
    )
})


export default LabResults