{
    "Dashboard": "Oma näkymä",
    "Patient search": "Potilashaku",
    "Sign out": "Kirjaudu ulos",
    "Summary": "Tiivistelmä",
    "Basic information": "Perustiedot",
    "Age": "Ikä",
    "Gender": "Sukupuoli",
    "Height": "Pituus",
    "Weight": "Paino",
    "Body mass index": "Painoindeksi",
    "Smokes": "Tupakoi",
    "Chronic diseases": "Pitkäaikaissairaudet",
    "Drug allergies": "Lääkeaineallergiat",
    "Blood pressure": "Verenpaine",
    "Medication list": "Lääkityslista",
    "Resting heart rate": "Leposyke",
    "Daily average steps": "Keskimääräiset askeleet päivässä",
    "Patient self-measurements": "Potilaan omat mittaukset",
    "Search": "Haku",
    "Search for a patient": "Hae potilasta",
    "My patients": "Omat potilaat",
    "Non-hippo users": "Eivät olet liittyneet systeemiin" ,
    "Date": "Päivämäärä",
    "Systolic": "Systolinen",
    "Diastolic": "Diastolinen",
    "Average": "Keskiarvo",
    "Steps": "Askeleet",
    "Lab results": "Laboratoriotulokset",
    "High": "Yläraja",
    "Low": "Alaraja"
}