import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getSummaryData } from 'root/helpers/api'
import { observer } from 'mobx-react-lite';
import { useStore } from 'root/store/context/store-context';

import './summary.less'

import icon from 'images/icons/summary-icon.svg'

// TODO: service to determine where data is from when we actually do the kanta integration. At that time construct a image selector that diplays the correct image according to the source


const Summary = observer(() => {
    const { t } = useTranslation()
    const { patientStore } = useStore();
    const [summary, setSummary] = useState(
        {
            birthDate: "",
            extension: [{ url: "", valueString: "" }],
            gender: "",
            id: "",
            resourceType: ""
        }
    );

    useEffect(() => {
        const fetchData = async () => {
            const id = patientStore.patientId?.toString()
            if (id) {
                const data = await getSummaryData(id)
                return data
            }
            return null
        }
        const result = fetchData().then((data) => setSummary(data))

    }, [])

    return (
        <div className="hippo-module summary-container">
            <h2>{t('Summary')}</h2>
            <img className="module-icon" src={icon} />
            <div className="patient-summary">
                {summary.extension[0].valueString}
            </div>
            <div className="hippo-source">
                <span className="source-txt"></span>
            </div>
        </div>
    )
})

export default Summary;