import React from 'react'

import { StoreProvider } from './store/context/store-context'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'

import RequireAuth from './auth/auth'
import { Amplify } from 'aws-amplify'

import Nav from './components/nav/nav'
import Dashboard from './views/dashboard/dashboard'
import EnrichPatientInfo from './views/enrich-patient-info/enrich-patient-info'
import Login from './views/login/login'
import PatientSearch from './views/patient-search/patient-search'

// TODO: automatic configuration for both dev/production, currently uses dev pool 
// TODO: get config from a separate file
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '1sv2gco3pmkq9vr5vefpvsp6t4',
      userPoolId: 'eu-north-1_csgkMxfTy',
    }
  }
})

export function App() {
  return (
    <StoreProvider>
      <div>
        <Router>
          <Nav />
          <Routes>
            <Route path="/*" element={<Navigate to="/patient-search" />} />
            <Route path="/login" element={<Login />} />
            {ProtectedRoutes()}
          </Routes>
        </Router>
      </div>
    </StoreProvider>
  );
}

function ProtectedRoutes() {
  return (
    <>
      <Route path="/patient-search" element={<RequireAuth><PatientSearch /></RequireAuth>} />
      <Route path="/dashboard/:id" element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route path="/enrich-patient-info" element={<RequireAuth><EnrichPatientInfo /></RequireAuth>} />
    </>
  )
}