import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useStore } from 'root/store/context/store-context';

import './chronic-diseases.less'

import { getChronicConditions } from 'root/helpers/api'

import image from 'images/kanta_logo.png';

import leavesImage from 'images/leaves-2.svg';

import icon from 'images/icons/ribbon-icon.svg';

// TODO: a smart way to handle the complex objects provided by HL7 FHIR. Go through the documentation to see if extension is prevalent
export default function ChronicDiseases() {
    const { t } = useTranslation();
    const { patientStore } = useStore();
    const [diseases, setDisease] = useState(
        {
            resourceType: "",
            id: "",
            extension: [{
                url: "",
                valueCodeableConcept: {
                    coding: [{
                        system: "",
                        code: "",
                        display: ""
                    }],
                    translation: [{
                        system: "",
                        code: "",
                        display: ""
                    }],

                },
                onsetDateTime: ""
            }]
        }
    )

    useEffect(() => {
        const fetchData = async () => {
            const id = patientStore.patientId?.toString()
            if (id) {
                const data = await getChronicConditions(id)
                return data
            }
            return null
        }
        const result = fetchData().then((data) => setDisease(data))
    }, [])

    return (
        <div className="hippo-module">
            <h2>{t('Chronic diseases')}</h2>
            <img className="module-icon" src={icon} />
            <div className="chronic-container">
                {diseases.extension.map((condition, i) => (
                    <div className="chronic-condition hippo-list-item" key={i}>
                        <div className="condition-row">
                            <div className="condition-code">

                                <img className="code-background" src={leavesImage} />
                                {condition.valueCodeableConcept.coding[0].code}

                            </div>
                            <div className="condition-info">
                                <div className="title">{condition.valueCodeableConcept.coding[0].display}</div>
                                <div className="diagnosis-date">{condition.onsetDateTime}</div>
                                <div className="translation">{condition.valueCodeableConcept.translation[0].display}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="hippo-source">
                <span className="source-txt"></span>
                <img className="source-logo" src={image} />
            </div>
        </div>
    )
}